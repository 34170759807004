import router from "@/router";
import config from './config.js';

function tolink(path) {
	router.push(path)
}

function getimg(img) {
	if (img != "") {
		return config.send_url + "upload/" + img;
	}
}

function setloc(key, value) {
	localStorage.setItem(key, value);
}

function getloc(key) {
	var val = localStorage.getItem(key);
	return val;
}

function sort_ascii(obj) { //签名
	let _sign = "";
	if (JSON.stringify(obj) != '{}') {

		let arr = new Array();
		let num = 0;
		for (let i in obj) {
			arr[num] = i;
			num++;
		}
		let sortArr = arr.sort();
		let str = config.Project;
		for (let i in sortArr) {
			str += sortArr[i] + '=' + obj[sortArr[i]] + '&';
		}

		//去除两侧字符串
		let char = '&'
		str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
		str += config.Project;
		const utilMd5 = require('./md5.js');
		_sign = utilMd5(str).toUpperCase();
	}

	return _sign;
}

export default {
	tolink,
	getimg,
	setloc,
	getloc,
	sort_ascii
};
