import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './language/index'

import HeadBar from './components/HeadBar.vue'

// 设置固定标题
document.title = "M1QUANTIFICATION AI"

const app = createApp(App)

app.component('HeadBar', HeadBar)

app.use(store).use(router).use(i18n).mount('#app')
