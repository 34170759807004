import { createI18n } from "vue-i18n";

import zh from './zh.json'
import en from './en.json'
import kor from './kor.json'
import may from './may.json'
import vie from './vie.json'
import th from './th.json'
import id from './id.json'
import jp from './jp.json'
import utils from "@/utils/utils";

const messages = {
  'zh': zh,
  'en': en,
  'kor': kor,
  'may': may,
  'vie': vie,
  'th': th,
  'id': id,
  'jp': jp
}

const i18n = createI18n({
  locale: utils.getloc('locale') || 'zh', // 设置当前语言
  fallbackLocale: 'zh', // 设置回退语言
  globalInjection: true, // 全局注册 $t 方法
  messages
})

export default i18n