import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/logo.png';
import _imports_1 from '@/assets/image/land.png';
import _imports_2 from '@/assets/image/menu.png';
import _imports_3 from '@/assets/image/back.png';
const _hoisted_1 = {
  class: "head-bar"
};
const _hoisted_2 = {
  class: "head-cell"
};
const _hoisted_3 = {
  class: "head-row"
};
const _hoisted_4 = {
  class: "address"
};
const _hoisted_5 = {
  key: 0,
  class: "level"
};
const _hoisted_6 = {
  class: "language-popover"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  key: 1,
  class: "head-title"
};
const _hoisted_10 = {
  class: "art"
};
import { ref } from 'vue';
import utils from '@/utils/utils';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import 'vant/lib/popover/style';
export default {
  __name: 'HeadBar',
  props: {
    title: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showBack: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const {
      t,
      locale
    } = useI18n();

    // 这个自动按需导入样式生效，单独引用
    // 问题未知

    const router = useRouter();
    const us = JSON.parse(utils.getloc('us'));
    let showMenu = ref(false);
    let menuList = [{
      text: t('shou-ye'),
      url: '/'
    }, {
      text: t('wo-de-zhang-dan'),
      url: 'bill'
    }, {
      text: t('wo-de-shou-yi'),
      url: 'bonus'
    }, {
      text: t('wo-de-zi-chan'),
      url: 'wallet'
    }];
    let showLanguage = ref(false);
    let languages = [{
      text: "中文繁體",
      val: "zh",
      img: require('@/assets/image/zh.png')
    }, {
      text: "English",
      val: "en",
      img: require('@/assets/image/en.png')
    }, {
      text: "한국어",
      val: "kor",
      img: require('@/assets/image/kor.png')
    }, {
      text: "Malay",
      val: "may",
      img: require('@/assets/image/may.png')
    }, {
      text: "Tiếng Việt",
      val: "vie",
      img: require('@/assets/image/vie.png')
    }, {
      text: "ภาษาไทย",
      val: "th",
      img: require('@/assets/image/th.png')
    }, {
      text: "Indonesia",
      val: "id",
      img: require('@/assets/image/id.png')
    }, {
      text: "日本語",
      val: "jp",
      img: require('@/assets/image/jp.png')
    }];
    function menuSelect(e) {
      utils.tolink(e.url);
    }
    function languageSelect(e) {
      locale.value = e.val;
      utils.setloc('locale', e.val);
      showLanguage.value = false;
    }
    function abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(val.substring(start, val.length - end), "****");
        return newVal;
      } else {
        return val;
      }
    }
    function onBack() {
      router.back();
    }
    return (_ctx, _cache) => {
      const _component_van_popover = _resolveComponent("van-popover");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        class: "head-logo"
      }, null, -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(abbr(_unref(utils).getloc('userid'), 4, 4)), 1), _unref(us) ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(us)?.ulevelName), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", null, [_createVNode(_component_van_popover, {
        show: _unref(showLanguage),
        "onUpdate:show": _cache[0] || (_cache[0] = $event => _isRef(showLanguage) ? showLanguage.value = $event : showLanguage = $event),
        placement: "bottom-end"
      }, {
        reference: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
          src: _imports_1,
          class: "menu-icon"
        }, null, -1)])),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(languages), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "language-item",
            key: index,
            onClick: $event => languageSelect(item)
          }, [_createElementVNode("img", {
            src: item.img,
            class: "language-icon"
          }, null, 8, _hoisted_8), _createElementVNode("div", null, _toDisplayString(item.text), 1)], 8, _hoisted_7);
        }), 128))])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_popover, {
        show: _unref(showMenu),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(showMenu) ? showMenu.value = $event : showMenu = $event),
        actions: _unref(menuList),
        placement: "bottom-end",
        onSelect: menuSelect
      }, {
        reference: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
          src: _imports_2,
          class: "menu-icon"
        }, null, -1)])),
        _: 1
      }, 8, ["show", "actions"])])]), __props.showBack ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _imports_3,
        class: "back-icon",
        onClick: onBack
      })) : _createCommentVNode("", true), __props.showTitle ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", null, _toDisplayString(__props.title), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(__props.label), 1)])) : _createCommentVNode("", true)]);
    };
  }
};